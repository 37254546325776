import React from "react";

// CSS
import styles from "./style.module.css";
import { Card, Spinner } from "react-bootstrap";

// Axios
import { getSnowman } from "utils/axios";

export default function SnowmanWallet({ tokenId, setSelectedNFT, setTrue }) {
  const [nft, setNft] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    try {
      setLoading(true);
      getSnowman(tokenId)
        .then((resp) => {
          setNft(resp.data);
        })
        .then(setLoading(false));
    } catch (error) {}
  }, [tokenId]);

  return (
    <React.Fragment>
      <Card
        style={{ minHeight: "350px", minWidth: "350px" }}
        className={styles.custom_card}
        onClick={() => {
          setSelectedNFT(nft);
          setTrue(true);
        }}
      >
        {loading === true ? (
          <Spinner animation="border" className="m-auto" />
        ) : nft !== undefined ? (
          <div>
            <span className={styles.card_body_text}>{nft.name}</span>
            <img
              src={nft.image}
              className={styles.custom_image}
              alt="nft_image"
            />
          </div>
        ) : (
          <Spinner animation="border" className="m-auto" />
        )}
      </Card>
    </React.Fragment>
  );
}
