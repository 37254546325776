import React from "react";

// CSS
import styles from "./style.module.css";

export default function MemberCard({ imageSrc, name, role }) {
  return (
    <div className={`${styles.card_container} my-2`}>
      <div className="d-flex justify-content-center pt-4">
        <img
          src={imageSrc}
          width={170}
          height={170}
          className="rounded-circle"
          alt="team_img"
        />
      </div>
      <div className="d-flex justify-content-center flex-column pt-3 pb-4">
        <p className={styles.name}>{name}</p>
        <p className={styles.role}>{role}</p>
      </div>
    </div>
  );
}
