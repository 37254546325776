import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// CSS
import styles from "./style.module.css";

// Image
import chikn from "images/dao/chikn.png";
import hon from "images/dao/hon.png";
import joe from "images/dao/joe.png";
import pangolin from "images/dao/pangolin.png";
import yeti from "images/dao/yeti.png";
import apa from "images/dao/apa.png";
import dick from "images/dao/dick.png";
import kalao from "images/logos/kalao.png";
import peng from "images/dao/peng.png";

const Item = ({ header, children, image }) => {
  return (
    <Row className={styles.customRow}>
      <Col lg={8} className="d-flex flex-column py-3">
        <span className={styles.itemHeader}>{header}</span>
        <span className={styles.itemContent}>{children}</span>
      </Col>
      <Col lg={4} className="d-flex">
        <div className="m-auto d-flex">
          <img
            src={image}
            className="w-75 m-auto"
            style={{ aspectRatio: 1 }}
            alt="friend_img"
          />
        </div>
      </Col>
    </Row>
  );
};

const GiveawayItem = ({ header, children, image }) => {
  return (
    <Row className={styles.customRow}>
      <Col lg={8} className="d-flex flex-column py-3">
        <span className={styles.itemHeader}>{header}</span>
        <span className={styles.itemContent}>{children}</span>
      </Col>
      <Col lg={4} className="d-flex ">
        <img
          src={image}
          className={
            header.includes("Party Animal") === true
              ? "w-25 m-auto"
              : "w-50 m-auto"
          }
          style={{ aspectRatio: 1 }}
          alt="friend_img"
        />
      </Col>
    </Row>
  );
};

const KalaoItem = ({ header, children, image }) => {
  return (
    <Row className={styles.customRow}>
      <Col lg={8} className="d-flex flex-column py-3">
        <span className={styles.itemHeader}>{header}</span>
        <span className={styles.itemContent}>{children}</span>
      </Col>
      <Col lg={4} className="d-flex">
        <img src={image} className={"w-75 m-auto"} alt="friend_img" />
      </Col>
    </Row>
  );
};

const DAOSection = () => {
  return (
    <>
      <div className="d-flex w-100 mt-5 mb-3">
        <span className={styles.daoHeader}>Snowman++ Giveaways</span>
      </div>
      <Container fluid className="d-flex w-100 flex-column mt-2">
        <Item header={"Joe"} image={joe}>
          <span>
            For each Joe friend selected, 0.2 Avax worth of $JOE will be bought,
            and will be distributed by giveaway among the users who select Joe
            friend.
          </span>
        </Item>
        <Item header={"Kernel"} image={chikn}>
          <span>
            For each Kernel friend selected, 0.2 Avax will be collected to
            another wallet. With Avax's collected at that wallet, we will sweep
            the Chikn floor and make giveaways between the users who pick
            Kernel!
          </span>
        </Item>
        <Item header={"HON Hologram"} image={hon}>
          <span>
            For each HON hologram selected, 0.2 Avax worth of $HON will be
            bought, and will be distributed by giveaway among the users who
            select the hologram.
          </span>
        </Item>
        <Item header={"Pangolin"} image={pangolin}>
          <span>
            For each Pangolin friend selected, 0.2 Avax worth of $PNG will be
            bought, and will be distributed by giveaway among the users who
            select the Pangolin.
          </span>
        </Item>

        <GiveawayItem header={"YETI"} image={yeti}>
          <span>
            For each Yeti friend selected, 0.2 Avax worth of $YTS will be
            bought, and will be distributed by giveaway among the users who
            select Yeti friend.
          </span>
        </GiveawayItem>
        <GiveawayItem header={"Party Animal"} image={apa}>
          <span>
            For each APA friend selected, 0.2 Avax will be collected to another
            wallet. With Avax's collected at that wallet, we will sweep the APA
            floor and make giveaways between the users who pick APA friend!
          </span>
        </GiveawayItem>
        <GiveawayItem header={"Picnic Tube"} image={dick}>
          <span>
            For each Picnic Tube selected, 0.2 Avax will be collected to another
            wallet. With Avax's collected at that wallet, we will sweep the
            Crypto Dickheads floor and make giveaways between the users who pick
            Picnic Tube!
          </span>
        </GiveawayItem>
        <GiveawayItem header={"Avax Penguin"} image={peng}>
          <span>
            For each Avax Penguin selected, 0.2 Avax will be collected to
            another wallet. With Avax's collected at that wallet, we will sweep
            the Avax Penguin Family floor and make giveaways between the users
            who pick Avax Penguin as a friend!
          </span>
        </GiveawayItem>
        <div className="d-flex w-100 mt-5 mb-3">
          <span className={styles.daoHeader}>KALAO</span>
        </div>
        <KalaoItem header={"Kalao"} image={kalao}>
          <span>
            Top 500 Kalao Stakers are whitelisted for Snowman++. <br />
            The team will buy 100 Avax worth of $KLO and stake it. Staking
            reward will be distributed to Snowman++ Holders with giveaways.
          </span>
        </KalaoItem>
      </Container>
    </>
  );
};

export default DAOSection;
