import axios from "axios";

const client = axios.create({
  baseURL: "https://api.snowmanppnft.com",
});

const getTicket = (tokenId) => {
  return client.get(`/api/ticket/${tokenId}`);
};

const getSnowman = (tokenId) => {
  return client.get(`/api/token/${tokenId}`);
};

export { getTicket, getSnowman };
