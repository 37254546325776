import React from "react";

// React Router Dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages

import { Home, Wallet, Customization, DAO } from "pages";

// Layout
import { Layout } from "components/ui";

// react-toastify

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        draggable
        pauseOnHover={false}
      />
      <Router>
        <Layout>
          <Switch>
            <Route component={Customization} path={"/customization"} />
            <Route component={Wallet} path="/wallet" />
            <Route component={DAO} path="/dao" />
            <Route component={Home} path="/" />
          </Switch>
        </Layout>
      </Router>
    </React.Fragment>
  );
}

export default App;
