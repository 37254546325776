import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { Web3Provider } from "components/providers";
import "bootstrap/dist/css/bootstrap.min.css";

import "./fonts/PoorStory/PoorStory-Regular.ttf";
import "./fonts/Poppins/Poppins-Medium.ttf";

ReactDOM.render(
  <Web3Provider>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    {/* <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      crossOrigin="true"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap"
      rel="stylesheet"
    ></link> */}
    <App />
  </Web3Provider>,
  document.getElementById("root")
);
