import React from "react";

import { useWeb3 } from "components/providers";

// CSS
import styles from "./style.module.css";

export default function SwitchPage() {
  const { switchNetwork } = useWeb3();
  return (
    <div className={styles.switch_container}>
      <p className={styles.switch_text}>
        To enter the arctic world of Snowman++
      </p>
      <div className={styles.switch_button_div} onClick={switchNetwork}>
        <p className={styles.switch_button_text}>SWITCH TO AVALANCHE</p>
      </div>
    </div>
  );
}
