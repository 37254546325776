import React from "react";

// CSS
import { Container, Modal, Row, Col, ListGroup } from "react-bootstrap";
import styles from "./style.module.css";

import { FaRegSnowflake } from "react-icons/fa";

export default function WalletModal({ show, onHide, nft }) {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      contentClassName={styles.custom_modal}
    >
      {nft ? (
        <React.Fragment>
          <Modal.Title className={styles.modal_title}>{nft.name}</Modal.Title>
          <Modal.Body>
            <Container>
              <Row className="mb-2">
                <Col lg="6" className="d-flex">
                  <img
                    src={nft.image}
                    width={"80%"}
                    alt="nft_image"
                    className={styles.image}
                  />
                </Col>
                <Col
                  lg="6"
                  className={`${styles.custom_col} d-flex flex-column`}
                >
                  <ListGroup className="w-100 m-auto">
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Hat</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[0].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[0].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Eyes</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[1].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[1].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Mouth</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[2].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[2].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Body</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[3].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[3].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Hand</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[4].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[4].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Friend</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {nft.attributes ? (
                            nft.attributes[5].value
                          ) : (
                            <FaRegSnowflake />
                          )}
                        </span>
                        {nft.attributes && (
                          <span className={styles.percentage_text}>
                            {nft.attributes[5].percentage}%
                          </span>
                        )}
                      </span>
                    </ListGroup.Item>
                  </ListGroup>

                  <div className="d-flex w-100 flex-row mt-4 mb-2">
                    {nft.attributes ? (
                      <React.Fragment>
                        <span
                          className={styles.rarity_circle}
                          style={
                            nft.attributes[6].value === "Common"
                              ? { backgroundColor: "gray" }
                              : nft.attributes[6].value === "Uncommon"
                              ? { backgroundColor: "green" }
                              : nft.attributes[6].value === "Rare"
                              ? { backgroundColor: "lightblue" }
                              : nft.attributes[6].value === "Epic"
                              ? { backgroundColor: "purple" }
                              : nft.attributes[6].value === "Genius"
                              ? { backgroundColor: "red" }
                              : nft.attributes[6].value === "Legendary"
                              ? { backgroundColor: "yellowgreen" }
                              : {}
                          }
                        />
                        <span className={`${styles.rarity_text}`}>
                          {nft.attributes[6].value}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <p
                    className="text-center fs-4 mt-2"
                    style={{ fontFamily: "Poppins , sans-serif" }}
                  >
                    <span
                      onClick={() => onHide()}
                      style={{ cursor: "pointer", borderRadius: 20 }}
                      className="px-4 py-1 bg-danger text-light"
                    >
                      Exit
                    </span>
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </React.Fragment>
      ) : null}
    </Modal>
  );
}
