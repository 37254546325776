import React from "react";

// CSS
import styles from "./style.module.css";
import { Modal, Row, Col, Container, Spinner } from "react-bootstrap";

// Hooks
import { useAccount } from "components/hooks/web3";
import { useWeb3 } from "components/providers";

import {
  hatState,
  eyeState,
  clothesState,
  mouthState,
  handState,
  friendState,
} from "utils/constants";

import { BsCheckLg } from "react-icons/bs";

const TicketCard = ({ ticket, setTimer }) => {
  const { nftContract } = useWeb3();
  const { account } = useAccount();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const createRandom = () => {
    let hatRandomIndex = Math.floor(Math.random() * hatState.length);
    let eyeRandomIndex = Math.floor(Math.random() * eyeState.length);
    let mouthRandomIndex = Math.floor(Math.random() * mouthState.length);
    let clothesRandomIndex = Math.floor(Math.random() * clothesState.length);
    let handRandomIndex = Math.floor(Math.random() * handState.length);
    let friendRandomIndex = Math.floor(Math.random() * friendState.length);

    while (mouthRandomIndex === 0) {
      mouthRandomIndex = Math.floor(Math.random() * mouthState.length);
    }

    while (handRandomIndex === 0) {
      handRandomIndex = Math.floor(Math.random() * handState.length);
    }

    return [
      hatRandomIndex,
      eyeRandomIndex,
      mouthRandomIndex,
      clothesRandomIndex,
      handRandomIndex,
      friendRandomIndex,
    ];
  };

  const onClick = async () => {
    try {
      var randArr = createRandom();

      setLoading(true);
      var u = await nftContract.methods
        .checkForUniqueness(
          randArr[0],
          randArr[1],
          randArr[2],
          randArr[3],
          randArr[4],
          randArr[5]
        )
        .call()
        .then((resp) => {
          return resp.isUnique;
        });

      while (u["isUnique"] === false) {
        randArr = createRandom();
        u = await nftContract.methods
          .checkForUniqueness(
            randArr[0],
            randArr[1],
            parseInt(randArr[2]) - 1,
            randArr[3],
            parseInt(randArr[4]) - 1,
            randArr[5]
          )
          .call()
          .then((resp) => {
            return resp.isUnique;
          });
      }

      await nftContract.methods
        .createUniqueNFT(
          randArr[0],
          randArr[1],
          parseInt(randArr[2]) - 1,
          randArr[3],
          parseInt(randArr[4]) - 1,
          randArr[5],
          ticket.tokenId
        )
        .send({
          from: account.data,
        });
      setLoading(false);
      setError(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <div className="d-flex">
      <div
        className={styles.ticketDiv}
        style={
          success === true && error === false && loading === false
            ? { background: "green" }
            : success === false && error === true && loading === false
            ? { background: "red" }
            : null
        }
        onClick={() => {
          if (error === false && success === false && loading === false) {
            onClick();
          }
        }}
      >
        {loading ? (
          <Spinner
            animation="border"
            className="m-auto"
            style={{ color: "white" }}
          />
        ) : ticket ? (
          <>
            {!error && success ? (
              <p className={styles.ticketText}>
                <BsCheckLg color="white" />
              </p>
            ) : error && !success ? (
              <p className={styles.ticketText}>{ticket.tokenId}</p>
            ) : (
              <p className={styles.ticketText}>{ticket.tokenId}</p>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default function RandomGenerateModal({ show, tickets, onHide }) {
  const [availableTickets, setAvailableTickets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (tickets) {
      let arr = [];
      setLoading(true);
      for (let i = 0; i < tickets.length; i++) {
        if (tickets[i]["isGold"] === false) {
          arr.push(tickets[i]);
        }
      }
      arr.sort((a, b) => a.tokenId - b.tokenId);
      setAvailableTickets(arr);
      setLoading(false);
    }
  }, [tickets]);

  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        onHide();
      }}
      size="xl"
      contentClassName={styles.custom_modal}
    >
      <p className={styles.header}>Create Snowman With One Click</p>
      <div>
        {loading ? (
          <p
            className="text-center fs-4 mt-3"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Loading....
          </p>
        ) : availableTickets.length === 0 ? (
          <p
            className="text-center fs-4 mt-3 text-danger"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            No available tickets
          </p>
        ) : (
          <Container fluid className="my-4">
            <Row xs={2} md={3} lg={6} className="g-3">
              {availableTickets.map((elem, idx) => (
                <Col key={idx}>
                  <TicketCard ticket={elem} setTimer={() => {}} />
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </div>
    </Modal>
  );
}
