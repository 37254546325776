export const hatState = [
  {
    name: "None",
  },
  {
    name: "Wizard Hat",
  },
  {
    name: "Afro",
  },
  {
    name: "Crown",
  },
  {
    name: "Mohawk",
  },
  {
    name: "Fez",
  },
  {
    name: "Devil Horns",
  },
  {
    name: "Unicorn Horn",
  },
  {
    name: "Headband",
  },
  {
    name: "Pirate Hat",
  },
  {
    name: "Deer Antler",
  },
  {
    name: "Axe",
  },
  {
    name: "Headphones",
  },
  {
    name: "Miner Helmet",
  },
  {
    name: "Cat Ears",
  },
  {
    name: "Magician Hat",
  },
  {
    name: "Drinking Helmet",
  },
  {
    name: "Elf Hat",
  },
  {
    name: "Naruto",
  },
  {
    name: "Garland",
  },
  {
    name: "Avalanche Hat",
  },
  {
    name: "Head Bandana",
  },
  {
    name: "Scar",
  },
  {
    name: "Viking Hat",
  },
  {
    name: "Cook Hat",
  },
];

export const eyeState = [
  {
    name: "None",
  },
  {
    name: "Eye Band",
  },
  {
    name: "3D Glasses",
  },
  {
    name: "Cookie Eyes",
  },
  {
    name: "Masquerade",
  },
  {
    name: "Pirate Eye Patch",
  },
  {
    name: "$$Eyes",
  },
  {
    name: "Wrestling Mask",
  },
  {
    name: "Ski Goggles",
  },
  {
    name: "Monocle",
  },
  {
    name: "VR Goggles",
  },
  {
    name: "Scar",
  },
  {
    name: "XX Eyes",
  },
  {
    name: "Laser Eyes",
  },
  {
    name: "Cyborg Eye",
  },
  {
    name: "Robocop",
  },
  {
    name: "Thug Life Glasses",
  },
  {
    name: "Potter Glasses",
  },
  {
    name: "Matrix Glasses",
  },
  {
    name: "Hypnosis",
  },
];

export const mouthState = [
  {
    name: "None",
  },
  {
    name: "Tongue Out",
  },
  {
    name: "Sad",
  },
  {
    name: "Cigarette",
  },
  {
    name: "Moustache",
  },
  {
    name: "Pipe",
  },
  {
    name: "Bandana",
  },
  {
    name: "Joker Smile",
  },
  {
    name: "Plague Mask",
  },
  {
    name: "Pacifier",
  },
  {
    name: "Kiss",
  },
  {
    name: "Venom Mouth",
  },
  {
    name: "Bubble Gum",
  },
  {
    name: "Rose",
  },
];

export const clothesState = [
  {
    name: "None",
  },
  {
    name: "Bikini",
  },
  {
    name: "Leather Jacket",
  },
  {
    name: "Garland",
  },
  {
    name: "Summer Jacket",
  },
  {
    name: "SWAT",
  },
  {
    name: "Angel Wings",
  },
  {
    name: "Avalanche Hoodie",
  },
  {
    name: "Duck Float",
  },
  {
    name: "Magician Cloak",
  },
  {
    name: "GOAT Jersey",
  },
  {
    name: "Armor",
  },
  {
    name: "Tuxedo",
  },
  {
    name: "Ballerina Skirt",
  },
  {
    name: "Mummy Wrap",
  },
  {
    name: "Jedi Cape",
  },
  {
    name: "Senator Tunic",
  },
  {
    name: "OG Necklace",
  },
  {
    name: "Disco Ball",
  },
  {
    name: "Cook Apron",
  },
  {
    name: "Superhero Cloak",
  },
  {
    name: "Marijuana",
  },
  {
    name: "Eros Arrow",
  },
  {
    name: "Royal Robe",
  },
  {
    name: "Bandolier",
  },
  {
    name: "Military Uniform",
  },
];

export const handState = [
  {
    name: "None",
  },
  {
    name: "Ice Mage",
  },
  {
    name: "Devil Spear",
  },
  {
    name: "Candy Stick",
  },
  {
    name: "Boxing Gloves",
  },
  {
    name: "Fire Mage",
  },
  {
    name: "Middle Finger",
  },
  {
    name: "Avalanche Cup",
  },
  {
    name: "Grim Reaper's Scythe",
  },
  {
    name: "Basket Ball Spinning",
  },
  {
    name: "Salt Bae",
  },
  {
    name: "Sword & Shield",
  },
  {
    name: "Lantern",
  },
  {
    name: "Dual Knives",
  },
  {
    name: "Wand",
  },
  {
    name: "Bogdanoff Call",
  },
  {
    name: "Foam Finger",
  },
  {
    name: "Lightsaber",
  },
  {
    name: "Infinity Gauntlet",
  },
  {
    name: "Beer",
  },
  {
    name: "AK-47",
  },
  {
    name: "Guitar",
  },
  {
    name: "Mic Drop",
  },
  {
    name: "Thor's Hammer",
  },
];

export const friendState = [
  {
    name: "None",
  },
  {
    name: "Turtle",
  },
  {
    name: "Penguin",
  },
  {
    name: "Seal",
  },
  {
    name: "Chick",
  },
  {
    name: "Cat",
  },
  {
    name: "Doge",
  },
  {
    name: "Little Snowman",
  },
  {
    name: "Dino",
  },
  {
    name: "Wolfie",
  },
  {
    name: "Igloo",
  },
  {
    name: "Dragon",
  },
  {
    name: "Parrot",
  },
  {
    name: "Fox",
  },
  {
    name: "Kernel",
  },
  {
    name: "Elf",
  },
  {
    name: "Satsdart",
  },
  {
    name: "Pepe",
  },
  {
    name: "Inversebrah",
  },
  {
    name: "HON Hologram",
  },
  {
    name: "Mini JOE",
  },
  {
    name: "Squanch",
  },
  {
    name: "Wojak",
  },
  {
    name: "Pasha",
  },
  {
    name: "Pangolin",
  },
  {
    name: "Yeti",
  },
  {
    name: "APA",
  },
  {
    name: "Picnic Tube",
  },
];

export const cdnURL = "https://snowman-cdn.herokuapp.com/";
