import React from "react";

// CSS
import styles from "./style.module.css";
import { Row, Col } from "react-bootstrap";

// Components
import { MemberCard } from "components/ui";

// Avatars
import berat from "images/common/avatars/berat.png";
import bugy from "images/common/avatars/bugy.png";
import dou from "images/common/avatars/dou.png";
import ferz from "images/common/avatars/ferz.png";
import kayra from "images/common/avatars/kayra.png";
import sena from "images/common/avatars/sena.png";

// Avax
import avax from "images/logos/poweredbyavax.png";

// Section
import { FAQ } from "components/ui";

export default function TeamSection() {
  const team = [
    {
      src: bugy,
      name: "Bugy",
      role: "Project Leader",
    },
    {
      src: berat,
      name: "0x2404",
      role: "Developer",
    },
    {
      src: kayra,
      name: "Demongoo",
      role: "Designer",
    },
    {
      src: sena,
      name: "Sena",
      role: "Artist",
    },
    {
      src: ferz,
      name: "Ferz",
      role: "Community Leader",
    },
    {
      src: dou,
      name: "Dou",
      role: "Community Leader",
    },
  ];

  return (
    <div className={`${styles.team_container} pt-5 pb-5`} id="#team">
      <div className={`container`}>
        <p className={styles.header}>Team</p>
        <p className={styles.content}>
          We are a few crypto degens studying at college
        </p>
        <Row className={`${styles.custom_row} g-4`} xs={1} md={2} lg={4}>
          {team.map((elem) => (
            <Col key={elem.name} className={styles.custom_col}>
              <MemberCard
                imageSrc={elem.src}
                name={elem.name}
                role={elem.role}
              />
            </Col>
          ))}
        </Row>
        <FAQ />
      </div>
      <div className={styles.poweredContainer}>
        <img
          src={avax}
          className={styles.poweredImage}
          alt="poweredbyavax.png"
          onClick={() => window.open("https://www.avax.network/", "__blank")}
        />
      </div>
    </div>
  );
}
