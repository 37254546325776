import React from "react";

// Hooks
import { useWeb3 } from "components/providers";
import { useMintedSupply, useMintState } from "components/hooks/web3";

// Gif
import snowman_gif from "images/common/gif/legendary_snowman_gif.gif";
import reward_gif from "images/common/gif/reward.gif";

// CSS
import styles from "./style.module.css";
import { Col, Row, Button } from "react-bootstrap";

import { Link as SLink } from "react-scroll";

// Sections
import { DAOSection, RaritySection } from "components/ui";

export default function MintSection() {
  const { ticketContract } = useWeb3();
  const { supply } = useMintedSupply(1);
  const { states } = useMintState();

  ticketContract?.events
    .EarlyMintStateStarted({}, function (error, event) {
      if (error) console.error(error);
    })
    .on("data", function (data) {
      states.mutate();
    });

  const onClick = () => {
    try {
      window.open("https://go.kalao.io/snowman++/", "__blank");
    } catch (error) {}
  };

  return (
    <div className={`container-fluid ${styles.background} `}>
      <div className={`${styles.bg_inner_section} ${styles.custom_container}`}>
        <Row>
          <Col>
            <p className={styles.about_header_text}>About Snowman++</p>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <p className={styles.content_text_1}>
              {"   "} In Snowman++, the users produce NFTs instead of the
              project team and the project offers a mini-game experience based
              on guessing other users’ choices. At the same time, the rarity of
              the accessories is determined by the choices of the community.{" "}
              <br />
              <br />
              The Snowman++ differs from other NFT projects and does not
              randomly generate NFTs and give them to users. Instead, you create
              the NFTs yourself. Accessories do not have a certain supply
              limitation. In Snowman++, you first get your snowman without
              accessories, and then you customize it with the accessories you
              want without any limitations. Thus, you determine the appearance
              of your snowman.
              <br />
              <br />
              The accessory choices that will be made by the users will
              determine the rarity levels. If an accessory is used lesser than
              another, that accessory will be automatically rarer. So, you need
              to guess the accessory choices of other users to create a rarer
              snowman! To create a precise rarity ranking, the customization
              phase will end after a while. If you do not customize your snowman
              in time, your snowman will be randomly generated.
              <br />
              Please check FAQ for detailed information.
            </p>
          </Col>
          <Col lg={5} className="d-flex">
            <div className={`${styles.img_container}`}>
              <img
                src={snowman_gif}
                className={`bg-white ${styles.image}`}
                alt="legendary_snowman_gif"
              />
            </div>
          </Col>
        </Row>
        <div className={styles.faq_div}>
          <SLink to="#faq" style={{ textDecoration: "none" }}>
            <span className={styles.faq_text}>FAQ</span>
          </SLink>
        </div>
     
      </div>
      <div className="container mt-5">
        <Row className=" d-flex">
          <Col lg={6} className="d-flex mx-auto flex-column">
            <Row
              className={`d-flex flex-row m-auto p-2 ${styles.rewardSideDiv}`}
            >
              <Col lg={6} className="d-flex">
                <div className={styles.img_container2}>
                  <img
                    src={reward_gif}
                    width="100%"
                    className={`${styles.reward_gif} p-3 `}
                    alt="reward_gif.gif"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex p-3 w-100 flex-column justify-content-center ">
                  <p className={styles.rewardHeader}>Rarity Prize Pool</p>
                  <p className={styles.subHeader}>1st Place</p>
                  <p className={styles.subHeaderReward}>10 AVAX</p>
                  <p className={styles.subHeader}>2nd Place</p>
                  <p className={styles.subHeaderReward}>9 AVAX</p>
                  <p className={styles.subHeader}>3th Place</p>
                  <p className={styles.subHeaderReward}>8 AVAX</p>
                  <p className={styles.subHeader}>4-99th Places</p>
                  <p className={styles.subHeaderReward}>4 AVAX</p>
                </div>
              </Col>
            </Row>
            <p style={{ marginBottom: 0 }} className={styles.infoText}>
              Rarest 99 NFT holders at reveal will be rewarded. So if you sell
              your hidden NFT and it is revealed as Genius Snowman, the buyer
              will receive avax rewards.
            </p>
          </Col>
        </Row>
      </div>
      <RaritySection />
      <DAOSection />
    </div>
  );
}
