import Ticket from "contracts/SnowmanTicket.json";
import NFT from "contracts/SnowmanNFT.json";

export const loadContract = async (name, web3) => {
  let Artifact;
  let address;
  if (name === "SnowmanTicket") {
    Artifact = Ticket;
    // address = "0x29A77fbE516c8aD9A3ED5eD9eC933B70B0727Ed7";
    address = Artifact.networks[43114].address;
  } else if (name === "SnowmanNFT") {
    Artifact = NFT;
    address = "0x9Db296Ea178bE5B22f7a635Baf8ff8B3d0bc115c";
    // address = Artifact.networks[5777].address;
  }
  let contract = null;
  try {
    contract = new web3.eth.Contract(Artifact.abi, address);
  } catch (err) {}
  return contract;
};
