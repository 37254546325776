import React from "react";

// Bootstrap
import { Modal, Row, Col, Container, ListGroup } from "react-bootstrap";

// Link
import { Link, useHistory } from "react-router-dom";

// CSS
import styles from "./style.module.css";

// CDN
import { cdnURL } from "utils/constants";

// States
import {
  hatState,
  eyeState,
  mouthState,
  clothesState,
  handState,
  friendState,
} from "utils/constants";
import { useAccount, useOwnedTickets } from "components/hooks/web3";

export default function ReceiptModal({
  show = true,
  progress = false,
  error = false,
  hat = 0,
  eye = 1,
  mouth = 2,
  body = 3,
  hand = 4,
  friend = 5,
}) {
  const history = useHistory();

  const { account } = useAccount();
  const { tickets } = useOwnedTickets(account.data);
  return (
    <Modal
      show={show}
      size="xl"
      centered
      contentClassName={styles.custom_modal}
      scrollable
    >
      <Modal.Title className={styles.modal_title}>
        {progress === true && error === false
          ? "LOADING"
          : progress === false && error === false
          ? "SUCCESS"
          : progress === false && error === true
          ? ""
          : ""}
      </Modal.Title>
      <Modal.Body>
        <Container>
          <p className={styles.sub_header}>
            {progress === true && error === false ? (
              "Waiting for transaction..."
            ) : progress === false && error === false ? (
              <span>
                Your new Snowman++ can be found in your wallet. <br /> To
                prevent accessory sniping, your NFT will be hidden for 1-2 days
              </span>
            ) : (
              ""
            )}
          </p>

          {progress === true && error === false ? null : progress === false &&
            error === false ? (
            <Container>
              <Row className="py-4">
                <Col lg="6">
                  <div className={styles.nftImage_bg_div}>
                    <div
                      className={styles.nftImage_base_div}
                      style={{
                        backgroundImage: `url("${cdnURL + "base/0.png"}")`,
                      }}
                    >
                      <div
                        className={styles.nft_div_layer}
                        style={{
                          backgroundImage: `url("${
                            cdnURL + "eye/" + eye + ".png"
                          }")`,
                        }}
                      >
                        <div
                          className={styles.nft_div_layer}
                          style={{
                            backgroundImage: `url("${
                              cdnURL + "hat/" + hat + ".png"
                            }")`,
                          }}
                        >
                          <div
                            className={styles.nft_div_layer}
                            style={{
                              backgroundImage: `url("${
                                cdnURL + "clothes/" + body + ".png"
                              }")`,
                            }}
                          >
                            <div
                              className={styles.nft_div_layer}
                              style={{
                                backgroundImage: `url("${
                                  cdnURL + "mouth/" + mouth + ".png"
                                }")`,
                              }}
                            >
                              <div
                                className={styles.nft_div_layer}
                                style={{
                                  backgroundImage: `url("${
                                    cdnURL + "hand/" + hand + ".png"
                                  }")`,
                                }}
                              >
                                <div
                                  className={styles.nft_div_layer}
                                  style={{
                                    backgroundImage: `url("${
                                      cdnURL + "friend/" + friend + ".png"
                                    }")`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="d-flex">
                  <ListGroup className={`${styles.custom_list_group} m-auto`}>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Hat</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {hatState[hat].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Eyes</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {eyeState[eye].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Mouth</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {mouthState[mouth].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Body</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {clothesState[body].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Hand</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {handState[hand].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.custom_list_item}>
                      <span className={styles.list_circle} />
                      <span className={styles.attribute_name}>Friend</span>
                      <span className={styles.attribute_real_name}>
                        <span className={styles.attribute_text}>
                          {friendState[friend].name}
                        </span>
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <div className="d-flex flex-row justify-content-center">
                <div
                  style={{ textDecoration: "none" }}
                  className={`${styles.button_div} my-2`}
                  onClick={() => history.push("wallet")}
                >
                  <span className={styles.button_text}>My Wallet</span>
                </div>
                <div
                  style={{ textDecoration: "none" }}
                  className={`${styles.button_div} my-2`}
                  onClick={() => {
                    tickets.mutate();
                    window.location.reload();
                  }}
                >
                  <span
                    className={styles.button_text}
                    style={{ backgroundColor: "green" }}
                  >
                    Use Another Ticket
                  </span>
                </div>
              </div>
            </Container>
          ) : progress === false && error === true ? (
            <p className="display-4 text-center text-danger">
              An error occured.
              <br />
              <Link to="/" style={{ textDecoration: "none" }}>
                <span
                  className={`${styles.redirect_text} display-6`}
                  style={{ cursor: "pointer" }}
                >
                  Click here for redirect home page.
                </span>
              </Link>
            </p>
          ) : null}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
