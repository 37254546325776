import { toast } from "react-toastify";

const notifyTicket = () => {
  toast.error("You must select ticket for customization", {
    position: "bottom-right",
    autoClose: 5000,
    style: { textAlign: "center", fontFamily: "Poppins , sans-serif" },
  });
};

const notifyIndex = () =>
  toast.error("Mouth or hand attribute can not be 'None'", {
    position: "bottom-right",
    autoClose: 5000,
    style: { textAlign: "center", fontFamily: "Poppins , sans-serif" },
  });

const notifyUnique = () => {
  toast.error("Snowman is not unique.", {
    position: "bottom-right",
    autoClose: 5000,
    style: { textAlign: "center", fontFamily: "Poppins , sans-serif" },
  });
};

const notifyForCheckUnique = () => {
  toast.error(
    "You need to check the uniqueness of Snowman from the check button next to create button",
    {
      position: "bottom-right",
      autoClose: 5000,
      style: { textAlign: "center", fontFamily: "Poppins , sans-serif" },
    }
  );
};

export { notifyTicket, notifyIndex, notifyUnique, notifyForCheckUnique };
