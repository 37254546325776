import useSWR from "swr";

export const handler = (web3, contract) => (account) => {
  const { data, ...rest } = useSWR(
    () =>
      web3 && contract && account ? `web3/ownedSnowmans/${account}` : null,
    async () => {
      const userCol = await contract.methods.getCollection(account).call();
      return userCol;
    }
  );

  return { data, ...rest };
};
