import React from "react";

// CSS
import styles from "./style.module.css";

import {
  Col,
  Row,
  Container,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import { TicketCard } from "components/ui";

// Hooks
import {
  useAccount,
  useNetwork,
  useOwnedSnowmans,
  useOwnedTickets,
} from "components/hooks/web3";
import { useWeb3 } from "components/providers";

// Link
import { Link } from "react-router-dom";

import {
  WalletModal,
  RandomGenerateModal,
  SwitchPage,
  ConnectPage,
  SnowmanWallet,
  LegendaryModal,
} from "components/ui";

// Helmet
import { Helmet } from "react-helmet";

const popover = (
  <Popover className={`${styles.custom_popover} border-0`}>
    <Popover.Body className={styles.custom_popover_body}>
      <div className={`d-flex flex-row ${styles.custom_row}`}>
        <div
          className={`${styles.rarity_circles}`}
          style={{ backgroundColor: "#a6b1c7" }}
        ></div>
        <p className={styles.popover_rarity_text}>Common</p>
      </div>
      <div className={`d-flex flex-row ${styles.custom_row}`}>
        <div
          className={`${styles.rarity_circles}`}
          style={{ backgroundColor: "#2dff53" }}
        ></div>
        <p className={styles.popover_rarity_text}>Uncommon</p>
      </div>
      <div className={`d-flex flex-row ${styles.custom_row}`}>
        <div
          className={`${styles.rarity_circles}`}
          style={{ backgroundColor: "#2af5ff" }}
        ></div>
        <p className={styles.popover_rarity_text}>Rare</p>
      </div>
      <div className={`d-flex flex-row ${styles.custom_row}`}>
        <div
          className={`${styles.rarity_circles}`}
          style={{ backgroundColor: "#b340ff" }}
        ></div>
        <p className={styles.popover_rarity_text}>Epic</p>
      </div>
      <div className={`d-flex flex-row ${styles.custom_row}`}>
        <div
          className={`${styles.rarity_circles}`}
          style={{ backgroundColor: "#ff2530" }}
        ></div>
        <p className={styles.popover_rarity_text}>Genius</p>
      </div>
    </Popover.Body>
  </Popover>
);

export default function Wallet() {
  const { connect, isLoading, requireInstall, nftContract } = useWeb3();
  const { network } = useNetwork();
  const { account } = useAccount();

  const { tickets } = useOwnedTickets(account.data);
  const { snowmans } = useOwnedSnowmans(account.data);

  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [legendaryModal, setLegendaryModal] = React.useState(false);
  const [selectedNFT, setSelectedNFT] = React.useState();
  const [selectedTicket, setSelectedTicket] = React.useState();

  const [randomModalVisibility, setRandomModalVisibility] =
    React.useState(false);

  nftContract?.events
    .SnowmanCreated({
      creator: account?.data,
      function(error, event) {
        if (error) console.error(error);
      },
    })
    .on("data", function (data) {
      snowmans.mutate();
      tickets.mutate();
    });
  return (
    <Container>
      <Helmet>
        <title>Snowman++ Wallet</title>
        <meta name="description" />
      </Helmet>
      {isLoading ? (
        <span className={styles.button_text}>Loading...</span>
      ) : (
        <React.Fragment>
          {network.hasInitialResponse && !network.isSupported ? (
            <SwitchPage />
          ) : account.data ? (
            <React.Fragment>
              <WalletModal
                show={modalVisibility}
                onHide={() => {
                  setModalVisibility(false);
                  tickets.mutate();
                }}
                nft={selectedNFT}
              />
              <LegendaryModal
                show={legendaryModal}
                onHide={() => setLegendaryModal(false)}
                ticket={selectedTicket}
              />

              <Row className="mt-4">
                <Col lg={8}>
                  <p className={styles.header}>My Wallet</p>
                </Col>
                <Col lg={4}></Col>
              </Row>
              <Row className="d-flex flex-row mt-4 mb-2">
                <Col md="6">
                  <p className={styles.ticket_header}>My Snowmen</p>
                </Col>
                <Col md="6" className="d-flex">
                  <div className={styles.rarity_div}>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={popover}
                    >
                      <div>
                        <span className={styles.rarity_text}>Rarity</span>
                        <AiFillQuestionCircle size={30} color="#1d3557" />
                      </div>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
              <Row xs={1} md={2} xl={3} className="g-4 pb-5 mb-5">
                {snowmans.data?.length === 0 || snowmans.data === undefined ? (
                  <p
                    className="text-center w-75 mx-auto pt-3 fs-4"
                    style={{ fontFamily: "Poppins , sans-serif" }}
                  >
                    You don{"'"}t have any snowman. Mint a ticket {"&"} use it
                    to customize your own snowman!
                  </p>
                ) : (
                  <React.Fragment>
                    {snowmans.data?.map((elem) => (
                      <React.Fragment key={elem}>
                        <Col className="d-flex">
                          <SnowmanWallet
                            tokenId={elem}
                            setSelectedNFT={setSelectedNFT}
                            setTrue={setModalVisibility}
                          />
                        </Col>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              </Row>
              <Row>
                <Col lg="4">
                  <p className={styles.ticket_header}>My Tickets</p>
                </Col>
                <Col lg="8">
                  {tickets.data === undefined ? null : tickets.data?.length !==
                    0 ? (
                    <div style={{ float: "right" }} className="">
                      <Button
                        className={`shadow-none  ${styles.use_ticket_button}`}
                        variant="outline-danger"
                        style={{ borderColor: "transparent" }}
                      >
                        <Link
                          to="/customization"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            className={styles.use_ticket_button_text}
                            style={{ borderColor: "transparent" }}
                          >
                            Use Ticket
                          </span>
                        </Link>
                      </Button>
                      <Button
                        onClick={() => {
                          tickets.mutate();
                          snowmans.mutate();
                          setRandomModalVisibility(true);
                        }}
                        className={`shadow-none  ${styles.use_ticket_button} ${styles.generate_random_button}`}
                        style={{ borderColor: "transparent" }}
                        variant="outline-danger"
                      >
                        <span className={styles.use_ticket_button_text}>
                          Generate Random Snowmen
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className={`shadow-none ${styles.use_ticket_button} h-100`}
                      variant="none"
                      onClick={() =>
                        window.open("https://go.kalao.io/snowman++/", "__blank")
                      }
                    >
                      <span style={{ textDecoration: "none" }}>
                        <span className={styles.use_ticket_button_text}>
                          Buy Ticket
                        </span>
                      </span>
                    </Button>
                  )}
                </Col>
              </Row>
              <Row
                xs={1}
                md={2}
                lg={4}
                className="g-4 mt-1"
                style={{ marginBottom: "10%" }}
              >
                {tickets.data?.length === 0 || tickets.data === undefined ? (
                  <p
                    className="text-center w-75 mx-auto pt-3 fs-4"
                    style={{ fontFamily: "Poppins , sans-serif" }}
                  >
                    You don{"'"}t have any ticket. Mint a ticket {"&"} use it to
                    customize your own snowman!
                  </p>
                ) : (
                  <React.Fragment>
                    {tickets.data?.map((elem) => (
                      <div key={elem["tokenId"]}>
                        <Col>
                          <TicketCard
                            tokenId={elem["tokenId"]}
                            isGold={elem["isGold"]}
                            onClick={() => {
                              setSelectedTicket(elem["tokenId"]);
                              setLegendaryModal(true);
                            }}
                          />
                        </Col>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </Row>
              <RandomGenerateModal
                show={randomModalVisibility}
                onHide={() => setRandomModalVisibility(false)}
                tickets={tickets.data}
              />
            </React.Fragment>
          ) : requireInstall ? (
            <Button
              variant="light"
              className="shadow-none"
              onClick={connect}
              style={{ borderRadius: "27px" }}
            >
              <span className={styles.button_text}>Install Metamask</span>
            </Button>
          ) : (
            <ConnectPage />
          )}
        </React.Fragment>
      )}
    </Container>
  );
}
