import useSWR from "swr";

export const handler = (web3, contract) => (d) => {
  const { data, ...rest } = useSWR(
    () => (web3 && contract ? `web3/mintStates/${d}` : null),
    async () => {
      const mintState = await contract.methods.canClaim().call();
      const earlyMintState = await contract.methods.earlyMint().call();

      return { mintState, earlyMintState };
    }
  );

  return { data, ...rest };
};
