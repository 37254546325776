import React from "react";

// CSS
import styles from "./style.module.css";

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onButtonClick = () => {
    this.openHeight = this.paragraph.offsetHeight;
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const style = { height: this.state.isOpen ? this.openHeight : "0px" };

    return (
      <div className={styles.accordionn}>
        <button
          className={styles.accordion__button}
          type="button"
          onClick={this.onButtonClick}
        >
          {this.props.question}
        </button>
        <div className={styles.accordion__drawer} style={style}>
          <p
            className={styles.accordion__paragraph}
            ref={(el) => (this.paragraph = el)}
          >
            {this.props.content}
          </p>
        </div>
      </div>
    );
  }
}

export default Accordion;
