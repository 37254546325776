import React from "react";

// CSS
import styles from "./styles.module.css";
import { Row } from "react-bootstrap";
import { Accordion } from "components/ui/accordion";

export default function FAQ() {
  const q = [
    {
      question:
        "What are the main differences between Snowman++ and other projects?",
      content:
        "In Snowman++, the users customize their own NFT instead of minting randomly generated NFTs.  The users will be able to choose accessories for their snowmen without any limitations.",
    },
    {
      question:
        "If there is no limitation in the selection of accessories, how will rarity be determined?",
      content:
        "The users’ choices will determine the rarity of the accessories & snowmen! The less-used accessories will be automatically the rarest ones. So, if you want to create a rare snowman, you need to guess other users’ choices correctly!",
    },
    {
      question:
        "How will be the rarity will be determined If not all NFTs are sold?",
      content:
        "Even if not all NFTs are sold, the mint & customization phase must be finished to make a precise rarity ranking. Thus, mint & customization phase will not last forever. So, if not all NFT’s are sold in 7 days, the Snowman++ NFT supply will decrease. ",
    },
    {
      question:
        "What is the mint price & launch date? Are there any royalties? ",
      content:
        "Mint price is 1 AVAX. If you are whitelisted, you will be able to mint a ticket for 0.8 Avax. There isn't minting royalty. The launch date is 9th of February and mint will be at KalaoGo. The marketplace will have 5% royalties which includes 2.5% to Snowman community wallet and 2.5% to the team.",
    },
    {
      question:
        "How to be whitelisted? What are the advantages of being whitelisted?",
      content:
        "Whitelisters will be able to mint a ticket for 0.8 Avax before the public sale date. To get whitelisted, you must help us to grow with our community. Join our discord for more information!",
    },
    {
      question: "How to customize a Snowman?",
      content:
        "First, you need to mint a ticket from Kalao. You will use these tickets to customize your snowman. At customization screen, you will choose 6 accessories for your snowman (Head, Eyes, Mouth, Clothes, Item, Friend) and create your Snowman++ NFT! There are 10 Golden Tickets as well. By using golden tickets, you will mint a legendary snowman. Each legendary snowmen have their own unique concept and cannot be customized.",
    },
  ];

  return (
    <div className="container mt-5" id="#faq">
      <Row>
        <div className={styles.faq_container}>
          <p className={styles.header}>FAQ</p>
          {q.map(({ question, content }) => (
            <Accordion question={question} content={content} key={question} />
          ))}
        </div>
      </Row>
    </div>
  );
}
