import React from "react";

// CSS
import styles from "./style.module.css";

// Hooks
import { useWeb3 } from "components/providers";

export default function ConnectPage() {
  const { connect } = useWeb3();
  return (
    <div className={styles.connect_container}>
      <div className="m-auto">
        <p className={styles.switch_text}>
          To enter the arctic world of Snowman++
        </p>
        <div className={styles.switch_button_div} onClick={connect}>
          <p className={styles.switch_button_text}>CONNECT</p>
        </div>
      </div>
    </div>
  );
}
