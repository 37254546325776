import React from "react";

// CSS
import styles from "./style.module.css";
import { Container, Row, Col } from "react-bootstrap";

// Images
import hon from "images/logos/hon.jpg";
import chickn from "images/logos/chickn.jpg";
import pangolin from "images/logos/pangolin.png";
import joe from "images/logos/joe.jpg";
import logo from "images/common/logo/logo.png";

const DAO = () => {
  return (
    <div className="w-100">
      <p
        className="text-center mt-3"
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: 30,
          color: "#1d3557",
        }}
      >
        SnowmanDAO
      </p>
      <Container className="d-flex flex-column">
        <Row className={styles.custom_row}>
          <Col md="8" className="d-flex">
            <p className={styles.dao_name}>Snowman DAO</p>
          </Col>
          <Col md="4" style={{ backgroundColor: "#1d3557" }}>
            <img src={logo} alt="snowman-logo.png" />
          </Col>
        </Row>
        <Row className={`${styles.custom_row} mt-3`}>
          <Col md="8" className="d-flex">
            <p className={styles.dao_name}>SnowChikn DAO</p>
          </Col>
          <Col md="4" className="d-flex" style={{ background: "#f33b47" }}>
            <img
              src={chickn}
              alt="snowman-logo.png"
              width={"30%"}
              style={{
                objectFit: "cover",
                margin: "auto",
                borderColor: "green",
              }}
            />
          </Col>
        </Row>
        <Row className={`${styles.custom_row} mt-3`}>
          <Col md="8" className="d-flex">
            <p className={styles.dao_name}>SnowHeroes DAO</p>
          </Col>
          <Col md="4" className="d-flex" style={{ backgroundColor: "#190f28" }}>
            <img
              src={hon}
              alt="snowman-logo.png"
              width={"30%"}
              style={{ objectFit: "cover", margin: "auto" }}
            />
          </Col>
        </Row>
        <Row className={`${styles.custom_row} mt-3`}>
          <Col md="8" className="d-flex">
            <p className={styles.dao_name}>SnowPNG DAO</p>
          </Col>
          <Col md="4" className="d-flex" style={{ backgroundColor: "#ff6b00" }}>
            <img
              src={pangolin}
              alt="snowman-logo.png"
              width={"30%"}
              style={{ objectFit: "cover", margin: "auto" }}
            />
          </Col>
        </Row>
        <Row className={`${styles.custom_row} mt-3 mb-5`}>
          <Col md="8" className="d-flex">
            <p className={styles.dao_name}>SnowJoe DAO</p>
          </Col>
          <Col md="4" className="d-flex" style={{ backgroundColor: "white" }}>
            <img
              src={joe}
              alt="snowman-logo.png"
              width={"30%"}
              style={{ objectFit: "cover", margin: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DAO;
