import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

// CSS
import styles from "./style.module.css";

// Images
import rarity from "images/common/gif/rarity.gif";

// Icon
import { FaRegSnowflake } from "react-icons/fa";

const RaritySection = () => {
  return (
    <>
      <Container className="mt-5 d-flex flex-column">
        <span className={styles.header}>Rarity</span>
      </Container>
      <Row className="m-auto" style={{ width: "90%" }}>
        <Col lg="6" className="p-4 d-flex flex-column">
          <div className={styles.content_div}>
            <span className="m-auto">
              As mentioned before, the community’s choices will determine the
              rarity rankings of accessories. While at the customization phase,
              NFT’s will be hidden to prevent accessory sniping.
            </span>
          </div>
          <img
            src={rarity}
            width={"40%"}
            className={`bg-light m-auto my-3 p-2 ${styles.custom_image}`}
            style={{ borderRadius: "20px" }}
            alt=".."
          />
          <div className={styles.content_div}>
            <span className="m-auto">
              When the customization finishes, all Snowman’s will be revealed
              with different background light colors which indicate rarity
              levels of Snowmen.
            </span>
          </div>
        </Col>
        <Col lg="6" className="d-flex">
          <Container fluid className={styles.table_container}>
            <Table responsive="lg" borderless>
              <tbody>
                <tr>
                  <td className="d-flex flex-row">
                    <span
                      className={styles.circle}
                      style={{ background: "#a6b1c7" }}
                    />
                    <span className={styles.rarity_name}>Common</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <span
                      className={styles.percentage_text}
                      style={{ color: "#a6b1c7" }}
                    >
                      %50
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex flex-row m-auto">
                    <span
                      className={styles.circle}
                      style={{ background: "#2dff53" }}
                    />
                    <span className={styles.rarity_name}>Uncommon</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <span
                      className={styles.percentage_text}
                      style={{ color: "#2dff53" }}
                    >
                      %24
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex flex-row m-auto">
                    <span
                      className={styles.circle}
                      style={{ background: "#2af5ff" }}
                    />
                    <span className={styles.rarity_name}>Rare</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <span
                      className={styles.percentage_text}
                      style={{ color: "#2af5ff" }}
                    >
                      %18
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex flex-row">
                    <span
                      className={styles.circle}
                      style={{ background: "#b340ff" }}
                    />
                    <span className={styles.rarity_name}>Epic</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <span
                      className={styles.percentage_text}
                      style={{ color: "#b340ff" }}
                    >
                      %8
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex flex-row m-auto">
                    <span
                      className={styles.circle}
                      style={{ background: "#ff2530" }}
                    />
                    <span className={styles.rarity_name}>Genius</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.percentage_text}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake size={40} />
                  </td>
                  <td>
                    <span
                      style={{ color: "#ff2530" }}
                      className={styles.percentage_text}
                    >
                      %1.8
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex flex-row m-auto">
                    <span
                      className={styles.circle}
                      style={{ background: "#ff9b42" }}
                    />
                    <span className={styles.rarity_name}>Legendary</span>
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <FaRegSnowflake
                      size={40}
                      className={styles.full_background}
                    />
                  </td>
                  <td>
                    <span
                      className={styles.percentage_text}
                      style={{ color: "#ff9b42" }}
                    >
                      %0.2
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default RaritySection;
