import useSWR from "swr";

import Web3 from "web3";

export const handler = (web3, contract) => (account) => {
  const { data, ...rest } = useSWR(
    () =>
      web3 && contract && account ? `web3/reflectionBalance/${account}` : null,
    async () => {
      const balance = await contract.methods.getReflectionBalances().call({
        from: account,
      });

      const avaxBalance = Web3.utils.fromWei(balance, "ether");

      if (avaxBalance === 0) {
        return avaxBalance;
      }
      let l = avaxBalance.split(".");
      if (l > 0) {
        let parsedBalance = l[0] + "." + l[1].slice(0, 4);
        return parsedBalance;
      } else {
        return avaxBalance;
      }
    }
  );

  return { data, ...rest };
};
