import useSWR from "swr";

export const handler = (eth, contract) => (account) => {
  const { data, ...rest } = useSWR(
    () => (eth && contract && account ? `eth/ownedTickets/${account}` : null),
    async () => {
      const resp = await contract.methods.userCollections(account).call();
      return resp;
    }
  );

  return { data, ...rest, isEmpty: !!data };
};
