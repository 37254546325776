import React from "react";

// Styles
import styles from "./style.module.css";

// Helmet
import { Helmet } from "react-helmet";

// Background Image
import background_image from "images/common/bg/customization_bg.png";

// Images
import left_arrow from "images/common/arrows/left-arrow.png";
import right_arrow from "images/common/arrows/right-arrow.png";

// Hooks
import { useAccount, useNetwork } from "components/hooks/web3";
import { useWeb3 } from "components/providers";

// Bootstrap
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { GiPerspectiveDiceSixFacesOne } from "react-icons/gi";
import { MdOutlineFingerprint } from "react-icons/md";

// Components
import {
  SwitchPage,
  ConnectPage,
  TicketModal,
  ReceiptModal,
} from "components/ui";

// react-toastify
import "react-toastify/dist/ReactToastify.css";

// Items arrays
import {
  handState,
  eyeState,
  friendState,
  hatState,
  clothesState,
  mouthState,
} from "utils/constants";

// CDN
import { cdnURL } from "utils/constants";
import {
  notifyIndex,
  notifyForCheckUnique,
  notifyTicket,
  notifyUnique,
} from "components/ui/toasts/Toasts";

const Customization = () => {
  const { account } = useAccount();
  const { network } = useNetwork();

  const { isLoading, requireInstall, nftContract } = useWeb3();

  const [selectedTicket, setSelectedTicket] = React.useState();
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [selectedAttribute, setSelectedAttribute] = React.useState(0);

  const [hatIndex, setHatIndex] = React.useState(0);
  const [mouthIndex, setMouthIndex] = React.useState(0);
  const [clothesIndex, setClothesIndex] = React.useState(0);
  const [handIndex, setHandIndex] = React.useState(0);
  const [eyeIndex, setEyeIndex] = React.useState(0);
  const [friendIndex, setFriendIndex] = React.useState(0);

  const [receiptVisibility, setReceiptVisibility] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [transactionError, setTransactionError] = React.useState(false);

  const [isUnique, setIsUnique] = React.useState(false);

  React.useEffect(() => {
    setModalVisibility(true);
  }, []);

  React.useEffect(() => {
    setIsUnique(false);
  }, [hatIndex, mouthIndex, clothesIndex, handIndex, eyeIndex, friendIndex]);

  const checkForUniqueness = async () => {
    try {
      if (mouthIndex === 0 || handIndex === 0) {
        notifyIndex();
        return;
      }

      const parsedHandIndex = parseInt(handIndex) - 1;
      const parsedMouthIndex = parseInt(mouthIndex) - 1;

      const r = await nftContract.methods
        .checkForUniqueness(
          hatIndex,
          eyeIndex,
          String(parsedMouthIndex),
          clothesIndex,
          String(parsedHandIndex),
          friendIndex
        )
        .call();

      if (r["isUnique"] === false) {
        notifyUnique();
        setIsUnique(false);
      } else {
        setIsUnique(true);
      }
    } catch (err) {}
  };

  const onClickCreate = async () => {
    try {
      if (selectedTicket === undefined) {
        notifyTicket();
        return;
      }

      if (mouthIndex === 0 || handIndex === 0) {
        notifyIndex();
        return;
      }

      if (isUnique === false) {
        notifyForCheckUnique();
        return;
      }

      const parsedHandIndex = parseInt(handIndex) - 1;
      const parsedMouthIndex = parseInt(mouthIndex) - 1;

      setProgress(true);
      setReceiptVisibility(true);

      let gasAmount = await nftContract.methods
        .createUniqueNFT(
          hatIndex,
          eyeIndex,
          String(parsedMouthIndex),
          clothesIndex,
          String(parsedHandIndex),
          friendIndex,
          selectedTicket
        )
        .estimateGas({ from: account.data });

      await nftContract.methods
        .createUniqueNFT(
          hatIndex,
          eyeIndex,
          String(parsedMouthIndex),
          clothesIndex,
          String(parsedHandIndex),
          friendIndex,
          selectedTicket
        )
        .send({ from: account.data, gas: String(gasAmount) })
        .then((resp) => {
          setTimeout(() => {
            setProgress(false);
            setTransactionError(false);
          }, 4000);
        });
    } catch (error) {
      setProgress(false);
      setTransactionError(true);
    }
  };

  const randomOnClick = () => {
    let hatRandomIndex = Math.floor(Math.random() * hatState.length);
    let eyeRandomIndex = Math.floor(Math.random() * eyeState.length);
    let mouthRandomIndex = Math.floor(Math.random() * mouthState.length);
    let clothesRandomIndex = Math.floor(Math.random() * clothesState.length);
    let handRandomIndex = Math.floor(Math.random() * handState.length);
    let friendRandomIndex = Math.floor(Math.random() * friendState.length);

    while (mouthRandomIndex === 0) {
      mouthRandomIndex = Math.floor(Math.random() * mouthState.length);
    }

    while (handRandomIndex === 0) {
      handRandomIndex = Math.floor(Math.random() * handState.length);
    }

    setHatIndex(hatRandomIndex);
    setEyeIndex(eyeRandomIndex);
    setMouthIndex(mouthRandomIndex);
    setClothesIndex(clothesRandomIndex);
    setHandIndex(handRandomIndex);
    setFriendIndex(friendRandomIndex);
  };

  return (
    <>
      <Helmet>
        <title>Snowman++ Customization</title>
        <meta name="description" content="Customize your own Snowman++ NFT!" />
        <style>{`body { background-image: url("${background_image}"); background-size:cover; min-height:100vm !important; background-repeat: no-repeat;}`}</style>
      </Helmet>
      <React.Fragment>
        {isLoading ? (
          <div className="w-100 h-100">
            <p
              style={{ fontFamily: "Poppins , sans-serif", marginTop: "50%" }}
              className="fs-1 text-center"
            >
              Loading
            </p>
          </div>
        ) : (
          <div style={{ minHeight: "90vmin" }} className="d-flex">
            {network.hasInitialResponse && !network.isSupported ? (
              <SwitchPage />
            ) : account.data ? (
              <React.Fragment>
                <TicketModal
                  show={modalVisibility} // modalVisibility
                  onHide={() => setModalVisibility(false)}
                  selectTicket={setSelectedTicket}
                />

                <Container fluid className="d-flex">
                  <Row
                    className={`d-flex w-100 ${styles.custom_biggest_row} `}
                    style={{ margin: "auto" }}
                  >
                    <Col lg="7" className="d-flex">
                      <div className={styles.attributes_div}>
                        <p className={styles.attributes_header}>Attributes</p>
                        <Container fluid className="d-flex flex-column">
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(0)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 0
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Head</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {hatState[hatIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {hatState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setHatIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(1)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 1
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Eyes</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {eyeState[eyeIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {eyeState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setEyeIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(2)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 2
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Mouth *</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {mouthState[mouthIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {mouthState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setMouthIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(3)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 3
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Body</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {clothesState[clothesIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {clothesState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setClothesIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(4)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 4
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Hand *</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {handState[handIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {handState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setHandIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <Row
                            className={styles.custom_row}
                            onClick={() => setSelectedAttribute(5)}
                          >
                            <Col lg={6} className="d-flex">
                              <span
                                className={styles.attributes_circle}
                                style={
                                  selectedAttribute === 5
                                    ? { backgroundColor: "#e63946" }
                                    : { backgroundColor: "white" }
                                }
                              />
                              <p className={styles.attributes_text}>Friend</p>
                            </Col>
                            <Col lg={6}>
                              <Dropdown className="d-flex">
                                <Dropdown.Toggle
                                  variant="light"
                                  className={`shadow-none ${styles.attributes_custom_dropdown}`}
                                  style={{ width: "80%" }}
                                >
                                  <span className={styles.attribute_name}>
                                    {friendState[friendIndex]?.name}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{
                                    width: "80%",
                                    maxHeight: 300,
                                    overflow: "auto",
                                  }}
                                >
                                  {friendState.map((elem, index) => (
                                    <React.Fragment key={index}>
                                      <Dropdown.Item
                                        className={`${styles.custom_dropdown_item}`}
                                        onClick={() => setFriendIndex(index)}
                                      >
                                        {elem.name}
                                      </Dropdown.Item>
                                      <Dropdown.Divider
                                        className={
                                          styles.custom_dropdown_divider
                                        }
                                      />
                                    </React.Fragment>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <div className="d-flex flex-row m-auto w-100 mb-2 ">
                            <span className={styles.infoText}>
                              `*` Selected attributes can't be None
                            </span>
                          </div>
                        </Container>
                      </div>
                    </Col>
                    <Col lg="5" className="d-flex">
                      <div className="m-auto">
                        <p className={styles.nft_header}>Your Snowman</p>
                        <div className="d-flex flex-row">
                          <div
                            className={styles.left_arrow_div}
                            onClick={() => {
                              switch (selectedAttribute) {
                                case 0:
                                  setHatIndex(
                                    hatIndex === 0
                                      ? hatState.length - 1
                                      : hatIndex - 1
                                  );
                                  break;
                                case 1:
                                  setEyeIndex(
                                    eyeIndex === 0
                                      ? eyeState.length - 1
                                      : eyeIndex - 1
                                  );
                                  break;
                                case 2:
                                  setMouthIndex(
                                    mouthIndex === 0
                                      ? mouthState.length - 1
                                      : mouthIndex - 1
                                  );
                                  break;
                                case 3:
                                  setClothesIndex(
                                    clothesIndex === 0
                                      ? clothesState.length - 1
                                      : clothesIndex - 1
                                  );
                                  break;
                                case 4:
                                  setHandIndex(
                                    handIndex === 0
                                      ? handState.length - 1
                                      : handIndex - 1
                                  );
                                  break;
                                case 5:
                                  setFriendIndex(
                                    friendIndex === 0
                                      ? friendState.length - 1
                                      : friendIndex - 1
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            <img
                              src={left_arrow}
                              width={30}
                              height={80}
                              alt="left_arrow"
                            />
                          </div>
                          <div className={styles.nft_div}>
                            <div
                              style={{
                                width: "94%",
                                aspectRatio: "1",
                                backgroundImage: `url("${
                                  cdnURL + "base/0.png"
                                }")`,
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                              }}
                              className="m-auto"
                            >
                              <div
                                className={styles.nft_div_layer}
                                style={{
                                  backgroundImage: `url("${
                                    cdnURL + "eye/" + eyeIndex + ".png"
                                  }")`,
                                }}
                              >
                                <div
                                  className={styles.nft_div_layer}
                                  style={{
                                    backgroundImage: `url("${
                                      cdnURL + "hat/" + hatIndex + ".png"
                                    }")`,
                                  }}
                                >
                                  <div
                                    className={styles.nft_div_layer}
                                    style={{
                                      backgroundImage: `url("${
                                        cdnURL +
                                        "clothes/" +
                                        clothesIndex +
                                        ".png"
                                      }")`,
                                    }}
                                  >
                                    <div
                                      className={styles.nft_div_layer}
                                      style={{
                                        backgroundImage: `url("${
                                          cdnURL +
                                          "mouth/" +
                                          mouthIndex +
                                          ".png"
                                        }")`,
                                      }}
                                    >
                                      <div
                                        className={styles.nft_div_layer}
                                        style={{
                                          backgroundImage: `url("${
                                            cdnURL +
                                            "hand/" +
                                            handIndex +
                                            ".png"
                                          }")`,
                                        }}
                                      >
                                        <div
                                          className={styles.nft_div_layer}
                                          style={{
                                            backgroundImage: `url("${
                                              cdnURL +
                                              "friend/" +
                                              friendIndex +
                                              ".png"
                                            }")`,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.right_arrow_div}
                            onClick={() => {
                              switch (selectedAttribute) {
                                case 0:
                                  setHatIndex(
                                    hatIndex === hatState.length - 1
                                      ? 0
                                      : hatIndex + 1
                                  );
                                  break;
                                case 1:
                                  setEyeIndex(
                                    eyeIndex === eyeState.length - 1
                                      ? 0
                                      : eyeIndex + 1
                                  );
                                  break;
                                case 2:
                                  setMouthIndex(
                                    mouthIndex === mouthState.length - 1
                                      ? 0
                                      : mouthIndex + 1
                                  );
                                  break;
                                case 3:
                                  setClothesIndex(
                                    clothesIndex === clothesState.length - 1
                                      ? 0
                                      : clothesIndex + 1
                                  );
                                  break;
                                case 4:
                                  setHandIndex(
                                    handIndex === handState.length - 1
                                      ? 0
                                      : handIndex + 1
                                  );
                                  break;
                                case 5:
                                  setFriendIndex(
                                    friendIndex === friendState.length - 1
                                      ? 0
                                      : friendIndex + 1
                                  );
                                  break;
                                default:
                                  break;
                              }
                            }}
                          >
                            <img
                              src={right_arrow}
                              width={30}
                              height={80}
                              alt="right_logo"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-around w-75 m-auto">
                          <MdOutlineFingerprint
                            size={50}
                            color={"white"}
                            style={{
                              padding: 5,
                              marginTop: "auto",
                              marginBottom: "auto",
                              cursor: "pointer",
                              borderRadius: 10,
                              backgroundColor: isUnique ? "green" : "red",
                            }}
                            onClick={async () => {
                              checkForUniqueness();
                            }}
                          />

                          <div
                            className={`${styles.create_button} py-2 px-5 shadow-none`}
                            onClick={onClickCreate}
                          >
                            <span className={styles.create_button_text}>
                              CREATE
                            </span>
                          </div>
                          <GiPerspectiveDiceSixFacesOne
                            size={45}
                            color={"white"}
                            className="my-auto"
                            style={{
                              cursor: "pointer",
                              borderRadius: 10,

                              backgroundColor: "#1d3557",
                            }}
                            onClick={randomOnClick}
                          />
                        </div>

                        <ReceiptModal
                          show={receiptVisibility}
                          progress={progress}
                          error={transactionError}
                          hat={hatIndex}
                          eye={eyeIndex}
                          mouth={mouthIndex}
                          body={clothesIndex}
                          hand={handIndex}
                          friend={friendIndex}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
            ) : requireInstall ? (
              <div className="w-100 h-100">
                <p
                  style={{
                    fontFamily: "Poppins , sans-serif",
                    marginTop: "50%",
                  }}
                  className="fs-1 text-center"
                >
                  Please Install Metamask.
                </p>
              </div>
            ) : (
              <ConnectPage />
            )}
          </div>
        )}
      </React.Fragment>
    </>
  );
};

export default Customization;
