import React from "react";
import { Container } from "react-bootstrap";

// Social Icon
import { SocialIcon } from "react-social-icons";

// CSS
import styles from "./styles.module.css";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <Container className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-around"
          style={{ minWidth: "50%" }}
        >
          <SocialIcon
            url="https://twitter.com/AvaxSnowmanNFT"
            fgColor="white"
            className="my-2"
            style={{
              width: 30,
              height: 30,
              borderColor: "black",
              borderWidth: 1,
            }}
            target="_blank"
          />
          <SocialIcon
            url="https://discord.gg/4Sb9enSUzJ"
            fgColor="white"
            className="my-2 "
            style={{
              width: 30,
              height: 30,
              borderColor: "black",
              borderWidth: 1,
            }}
            target="_blank"
          />
          <SocialIcon
            url="https://medium.com/@snowmanppnft"
            fgColor="white"
            className="my-2 "
            style={{
              width: 30,
              height: 30,
              borderColor: "black",
              borderWidth: 1,
            }}
            target="_blank"
          />
        </div>
      </Container>
    </div>
  );
}
