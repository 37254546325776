import { handler as createAccountHook } from "./useAccount";
import { handler as createNetworkHook } from "./useNetwork";
import { handler as createMintedSupplyHook } from "./useMintedSupply";
import { handler as createOwnedTicketsHook } from "./useOwnedTickets";
import { handler as createOwnedSnowmansHook } from "./useOwnedSnowmans";
import { handler as createReflectionHook } from "./useReflectionBalance";
import { handler as createMintStateHook } from "./useMintState";

export const setupHooks = (web3, provider, ticketContract, nftContract) => {
  return {
    useAccount: createAccountHook(web3, provider),
    useNetwork: createNetworkHook(web3),
    useMintedSupply: createMintedSupplyHook(web3, ticketContract),
    useOwnedTickets: createOwnedTicketsHook(web3, ticketContract),
    useReflectionBalance: createReflectionHook(web3, ticketContract),
    useOwnedSnowmans: createOwnedSnowmansHook(web3, nftContract),
    useMintState: createMintStateHook(web3, ticketContract),
  };
};
