import React from "react";

// CSS
import styles from "./styles.module.css";
import { Card, Spinner } from "react-bootstrap";

// Client
import { getTicket } from "utils/axios";

export default function TicketCard({ tokenId, isGold, onClick }) {
  const [ticket, setTicket] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    try {
      if (tokenId !== undefined)
        getTicket(tokenId)
          .then((resp) => {
            setTicket(resp.data);
          })
          .then(setLoading(false));
    } catch (err) {}
  }, [tokenId]);

  return (
    <Card className={`border-0 p-2 ${styles.custom_card}`}>
      {loading === true ? (
        <Spinner animation="border" />
      ) : ticket !== undefined ? (
        <div
          style={isGold === true ? { cursor: "pointer" } : null}
          onClick={isGold === true ? onClick : null}
        >
          <Card.Img
            variant="top"
            src={ticket.image}
            className={styles.cardImage}
          />
          <Card.Body>
            <Card.Title className={styles.card_title_div}>
              <span className={styles.card_title_token}>
                TokenID : #{ticket.tokenId}
              </span>
              {isGold === true ? (
                <span className={styles.card_title}>Gold Ticket</span>
              ) : (
                <span className={styles.card_title}>Customization Ticket</span>
              )}
            </Card.Title>
          </Card.Body>
        </div>
      ) : null}
    </Card>
  );
}
