import React from "react";

// CSS
import styles from "./style.module.css";

// Gif
import snowman_gif from "images/common/gif/snowman_gif.gif";

// Images
import hon from "images/logos/hon.jpg";
import kaira from "images/logos/kaira.png";
import chickn from "images/logos/chickn.jpg";
import yeti from "images/logos/yeti.png";
import apa from "images/logos/apa.png";
import dickheads from "images/logos/dickheads.png";
import kalao from "images/logos/kalao.png";
import penguin from "images/logos/penguin.jpg";

// Bootstrap
import { Row, Col, Card } from "react-bootstrap";

// Sections
import { MintSection, TeamSection } from "components/ui";

// Helmet
import { Helmet } from "react-helmet";

// Icon
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineWeb } from "react-icons/md";

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Snowman++</title>
        <meta
          name="description"
          content="Snowman++ is the first community-driven project where users customize their own NFT to determine rarity rankings."
        />
      </Helmet>

      <div className="container-fluid">
        <Row className={`${styles.background}`}>
          <Col lg={6} className="d-flex">
            <div className={styles.img_container}>
              <img
                src={snowman_gif}
                className={`${styles.Image} p-2`}
                alt="snowman_gif"
              />
            </div>
          </Col>
          <Col lg={6} className="d-flex justify-content-center flex-column">
            <p className={`text-center ${styles.header_text_1}`}>
              Snowman++ is the first community-driven project where users can customize their own NFT to determine rarity
              rankings.{" "}
            </p>
            <p className={`text-center ${styles.header_text_2}`}>
              Guess other users Choices {"&"} Create the rarest Snowman to win
              prizes up to 10 AVAX.
              <br />
              Pick partnership friends to join giveaways.
            </p>
          </Col>
        </Row>
      </div>
      <MintSection />
      <div className={`${styles.partners_container}`}>
        <div className="container">
          <p className={styles.partners_header}>Partners</p>
          <Row xs={1} md={2} lg={4} className="g-4 mt-2 pb-2">
            {Array.from([
              {
                img: chickn,
                name: "chikn",
                url: "https://chikn.farm/",
                src: "https://medium.com/@snowmanppnft/snowman-x-chikn-collaboration-details-snowchikn-dao-8448af40a28b",
              },
              {
                img: hon,
                name: "Heroes Of NFT",
                url: "https://heroesofnft.com/",
                src: "https://medium.com/@snowmanppnft/snowman-x-heroes-of-nft-partnership-details-snowheroes-dao-8d3f7a54bbdc",
              },
              {
                img: yeti,
                name: "Yeti Swap",
                url: "https://yetiswap.app/",
                src: "https://twitter.com/AvaxSnowmanNFT/status/1484225725179875331",
              },
              {
                img: kaira,
                url: "https://www.kaira.network/",
                name: "Kaira Network",
              },
              {
                img: apa,
                name: "Party Animals",
                url: "https://partyanimals.xyz/",
                src: "https://twitter.com/AvaxSnowmanNFT/status/1488152912656228359",
              },
              {
                img: dickheads,
                url: "https://cryptodickheads.com/",
                name: "Crypto Dickheads",
                src: "https://twitter.com/AvaxSnowmanNFT/status/1489970888174936071",
              },
              {
                img: kalao,
                name: "Kalao",
                url: "https://www.kalao.io/",
                src: "https://twitter.com/AvaxSnowmanNFT/status/1488877302415073283",
              },
              {
                img: penguin,
                name: "Avax Penguin Family",
                url: "https://www.avaxpenguinfamily.com/  ",
                src: "https://twitter.com/AvaxSnowmanNFT/status/1489271500062535680",
              },
            ]).map((elem, idx) => (
              <Col className="m-auto border-0 mb-1" key={elem.name}>
                <Card
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 0,
                  }}
                  className="d-flex"
                >
                  <Card.Img
                    variant="top"
                    src={elem.img}
                    className={styles.partner_image}
                  />
                  <Card.Body>
                    <Card.Title className="text-center text-light">
                      {elem.name}
                      <br />
                      {elem.src && (
                        <a
                          href={elem.src}
                          target="__blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <FaInfoCircle style={{ cursor: "pointer" }} />
                        </a>
                      )}
                      <a
                        href={elem.url}
                        target="__blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <MdOutlineWeb
                          style={{ cursor: "pointer" }}
                          size={24}
                          className="ml-5"
                        />
                      </a>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div className="d-flex">
          <div className="d-flex mb-2 flex-row m-auto">
            <FaInfoCircle
              size={30}
              color="white"
              className="my-auto"
              style={{ marginRight: 20 }}
            />
            <p
              style={{
                fontFamily: "Poppins,sans-serif",
                fontSize: 25,
                color: "white",
              }}
              className="my-auto"
            >
              Partnership Details
            </p>
          </div>
        </div>
      </div>
      <TeamSection />
    </React.Fragment>
  );
};

export default Home;
