import React from "react";

// CSS
import styles from "./style.module.css";

// Bootstrap
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useAccount, useOwnedTickets } from "components/hooks/web3";

// Axios
import { getTicket } from "utils/axios";

import { useHistory } from "react-router-dom";

const TicketSelectCard = ({ tokenId, handleClose, selectTicket, h }) => {
  const [ticket, setTicket] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    try {
      if (tokenId !== undefined) {
        setLoading(true);
        getTicket(tokenId)
          .then((resp) => {
            setTicket(resp.data);
          })
          .then(setLoading(false));
      }
    } catch (err) {}
  }, [tokenId]);

  return (
    <React.Fragment>
      <div
        className="bg-white w-75 m-auto d-flex p-3"
        style={{
          cursor: "pointer",
          borderRadius: 30,
        }}
        onClick={() => {
          if (ticket?.name !== "Snowman++: Golden Customization Ticket")
            selectTicket(tokenId);
          else h.push("/wallet");
          handleClose();
        }}
        key={tokenId}
      >
        {loading || ticket === undefined ? (
          <p>Loading</p>
        ) : (
          <img
            src={ticket["image"]}
            width={"90%"}
            height={"50%"}
            className="m-auto"
            alt="token_image"
          />
        )}
      </div>
      <p
        className="text-center mt-1"
        style={{ fontFamily: "Poppins, sans-serif", marginBottom: 0 }}
      >
        Ticket ID #{tokenId}
      </p>
    </React.Fragment>
  );
};

const TicketModal = ({ show, onHide, selectTicket }) => {
  const { account } = useAccount();
  const { tickets } = useOwnedTickets(account.data);

  const [isRegular, setIsRegular] = React.useState(true);
  const history = useHistory();

  return (
    <React.Fragment>
      <Modal
        show={show}
        size="xl"
        scrollable={true}
        centered
        contentClassName={styles.custom_modal}
      >
        <Modal.Title className={styles.modal_title_div}>
          <span className={styles.modal_title}>SELECT TICKET</span>
          <div className={styles.modal_title_ticket_type_div}>
            <div
              style={{ cursor: "pointer" }}
              className={
                isRegular === true
                  ? `shadow-none border-0 ${styles.ticket_regular_div} ${styles.ticket_regular_active_div}`
                  : `shadow-none  ${styles.ticket_regular_div} ${styles.ticket_regular_passive_div}`
              }
              onClick={() => setIsRegular(true)}
            >
              <span className={styles.ticket_regular_text}>
                Regular (
                {tickets.data === undefined
                  ? "0"
                  : tickets.data?.filter((e) => e["isGold"] === false).length}
                )
              </span>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className={
                isRegular !== true
                  ? `shadow-none border-0 ${styles.ticket_regular_div} ${styles.ticket_golden_active_div}`
                  : `shadow-none  ${styles.ticket_regular_div} ${styles.ticket_golden_passive_div}`
              }
              onClick={() => setIsRegular(false)}
            >
              <span className={styles.ticket_regular_text}>
                Golden (
                {tickets.data === undefined
                  ? "0"
                  : tickets.data?.filter((e) => e["isGold"] === true).length}
                )
              </span>
            </div>
          </div>
          {tickets.data?.filter((e) => e["isGold"] === true).length > 0 &&
            !isRegular && (
              <span className={styles.goldTitleText}>
                You can summon Legendary Snowman from Wallet page.
              </span>
            )}
        </Modal.Title>
        <Modal.Body className="my-2">
          <Row xs={1} md={2} lg={3} className="g-3">
            {isRegular ? (
              <React.Fragment>
                {tickets.data?.filter((e) => e["isGold"] === false).length ===
                  0 || tickets.data === undefined ? (
                  <React.Fragment>
                    <p
                      className="text-center m-auto mt-sm-4"
                      style={{
                        fontFamily: "Poppins , sans-serif",
                        fontSize: 20,
                      }}
                    >
                      You don{"'"}t have any{" "}
                      <span style={{ color: "blue" }}>Regular</span> ticket!
                    </p>
                    <Button
                      className="text-center m-auto mt-sm-4 shadow-none"
                      style={{
                        fontFamily: "Poppins , sans-serif",
                        fontSize: 20,
                        backgroundColor: "#1d3557",
                        border: 0,
                      }}
                      onClick={() => {
                        window.location.replace("/");
                      }}
                    >
                      Buy Ticket
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {tickets.data
                      ?.filter((e) => e["isGold"] === false)
                      .map((elem) => (
                        <Col key={elem["tokenId"]}>
                          <TicketSelectCard
                            tokenId={elem["tokenId"]}
                            handleClose={() => onHide()}
                            selectTicket={selectTicket}
                            h={history}
                          />
                        </Col>
                      ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {tickets.data?.filter((e) => e["isGold"] === true).length ===
                  0 || tickets.data === undefined ? (
                  <p
                    className="text-center m-auto mt-sm-4"
                    style={{
                      fontFamily: "Poppins , sans-serif",
                      fontSize: 20,
                    }}
                  >
                    You don{"'"}t have any{" "}
                    <span style={{ color: "goldenrod" }}>Golden</span> ticket!
                  </p>
                ) : (
                  <React.Fragment>
                    {tickets.data
                      ?.filter((e) => e["isGold"] === true)
                      .map((elem) => (
                        <React.Fragment>
                          <Col key={elem["tokenId"]}>
                            <TicketSelectCard
                              tokenId={elem["tokenId"]}
                              handleClose={() => onHide()}
                              h={history}
                            />
                          </Col>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default TicketModal;
