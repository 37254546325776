import useSWR from "swr";

export const handler = (web3, contract) => (d) => {
  const { data, ...rest } = useSWR(
    () => (web3 && contract && d ? `web3/mintedSupply/${d}` : null),
    async () => {
      const m = await contract.methods.mintedSupply().call();

      return m;
    }
  );

  return { data, ...rest };
};
