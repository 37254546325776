// React
import React from "react";

// Hooks
import { useAccount, useNetwork } from "components/hooks/web3";
import { useWeb3 } from "components/providers";

// Images
import logo from "images/common/logo/logo.png";

// Bootstrap
import { Navbar, Button, Nav, Dropdown } from "react-bootstrap";

// CSS
import styles from "./style.module.css";

// Icons
import { Sling as Hamburger } from "hamburger-react";
import { BsWallet2 } from "react-icons/bs";

// Link
import { Link } from "react-router-dom";

const Header = () => {
  const { connect, isLoading, requireInstall, provider } = useWeb3();
  const { account } = useAccount();
  const { network } = useNetwork();

  return (
    <Navbar expand="lg" style={{ backgroundColor: "#1d3557" }}>
      <div className="container">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height={"70"}
            className="d-inline-block mt-auto mb-auto"
            alt="Snowman++ Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle style={{ borderWidth: 0 }}>
          <Hamburger color="#ffffff" />
        </Navbar.Toggle>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="mr-auto">
            <Link to="/" className={styles.link_container}>
              <span className={styles.navbar_text}>Home</span>
            </Link>
            {/* <span
              onClick={() =>
                window.open("https://go.kalao.io/snowman++/", "__blank")
              }
              className={styles.link_container}
            >
              <span className={styles.navbar_text}>Mint</span>
            </span> */}
            <span
              className={styles.link_container}
              onClick={() =>
                window.open(
                  "https://marketplace.kalao.io/collection/0x9db296ea178be5b22f7a635baf8ff8b3d0bc115c",
                  "__blank"
                )
              }
            >
              <span className={styles.navbar_text}>Marketplace</span>
            </span>
            {/* <Link to="/dao" className={styles.link_container}>
              <span className={styles.navbar_text}>DAO {"(Soon)"}</span>
            </Link> */}

            {/* {account.data === undefined ? (
              <Nav.Item className="d-flex">
                <Button
                  variant="light"
                  className="shadow-none m-auto"
                  style={{ borderRadius: "27px" }}
                  onClick={connect}
                >
                  <span className={styles.button_text}>Connect</span>
                </Button>
              </Nav.Item>
            ) : network.hasInitialResponse && !network.isSupported ? (
              <Nav.Item className="d-flex">
                <Button
                  variant="light"
                  className="shadow-none m-auto"
                  style={{ borderRadius: "27px" }}
                  onClick={async () => {
                    if (provider) {
                      try {
                        await provider.request({
                          method: "wallet_switchEthereumChain",
                          params: [{ chainId: "0xa86a" }],
                        });
                        network.mutate();
                      } catch (error) {
                        window.location.reload();
                      }
                    }
                  }}
                >
                  <span className={styles.button_text}>
                    Switch to AVAX C-Chain
                  </span>
                </Button>
              </Nav.Item>
            ) : (
              <React.Fragment>
                {isLoading ? (
                  <Nav.Item className="d-flex">
                    <Button
                      variant="light"
                      className="shadow-none"
                      style={{ borderRadius: "27px" }}
                    >
                      <span className={styles.button_text}>Loading...</span>
                    </Button>
                  </Nav.Item>
                ) : account.data ? (
                  <Nav.Item className="d-flex">
                    <Dropdown align="end" className="m-auto">
                      <Dropdown.Toggle
                        className={`shadow-none border-0 ${styles.dropdown_toggle}`}
                        style={{ backgroundColor: "transparent" }}
                      >
                        <BsWallet2
                          size={25}
                          style={{ marginBottom: "auto", marginTop: "auto" }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ backgroundColor: "#1d3557" }}>
                        <Dropdown.Item
                          className={styles.dropdown_item}
                          style={{ cursor: "default", color: "white" }}
                        >
                          {account.data?.substring(0, 10)}
                          {"..."}
                        </Dropdown.Item>
                        <Dropdown.Item className={styles.dropdown_item}>
                          <Link to="/wallet" style={{ textDecoration: "none" }}>
                            <span
                              style={{
                                textDecoration: "none",
                                color: "white",
                              }}
                            >
                              My Wallet
                            </span>
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Item>
                ) : requireInstall ? (
                  <Nav.Item className="d-flex">
                    <Button
                      variant="light"
                      className="shadow-none"
                      onClick={() =>
                        window.open(
                          "https://metamask.io/download.html",
                          "_blank"
                        )
                      }
                      style={{ borderRadius: "27px" }}
                    >
                      <span className={styles.button_text}>
                        Install Metamask
                      </span>
                    </Button>
                  </Nav.Item>
                ) : (
                  <Nav.Item className="d-flex">
                    <Button
                      variant="light"
                      className="shadow-none m-auto"
                      style={{ borderRadius: "27px" }}
                      onClick={() => connect()}
                    >
                      <span className={styles.button_text}>Connect</span>
                    </Button>
                  </Nav.Item>
                )}
              </React.Fragment>
            )} */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
