import React from "react";

// CSS
import styles from "./style.module.css";
import { Modal, Spinner } from "react-bootstrap";
import { getTicket } from "utils/axios";

// Hooks
import {
  useAccount,
  useOwnedSnowmans,
  useOwnedTickets,
} from "components/hooks/web3";
import { useWeb3 } from "components/providers";

const LegendaryModal = ({ show, onHide, ticket }) => {
  const { tickets } = useOwnedTickets();
  const { snowmans } = useOwnedSnowmans();
  const { account } = useAccount();

  const { nftContract } = useWeb3();

  const [t, setT] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    try {
      if (ticket !== undefined) {
        getTicket(ticket).then((resp) => {
          setT(resp.data);
        });
      }
    } catch (error) {}
  }, [ticket]);

  const onClick = async () => {
    try {
      if (!loading) {
        await nftContract.methods.createLegendaryNFT(ticket).send({
          from: account.data,
        });
        tickets?.mutate();
        snowmans?.mutate();
        setLoading(false);
        onHide();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      contentClassName={styles.custom_modal}
    >
      <Modal.Title className={styles.modal_title}>
        Summon a Legendary Snowman
      </Modal.Title>
      {t !== undefined ? (
        <Modal.Body className="d-flex w-100 flex-column">
          <img
            src={t.image}
            className={`m-auto ${styles.Image}`}
            alt="golden_ticket"
          />
          <div className={`mx-auto ${styles.SummonButton}`}>
            {loading ? (
              <Spinner
                animation="grow"
                className="m-auto"
                style={{ color: "#1d3557" }}
              />
            ) : (
              <span className={styles.SummonText} onClick={onClick}>
                Summon
              </span>
            )}
          </div>
        </Modal.Body>
      ) : (
        <Spinner animation="border" />
      )}
    </Modal>
  );
};

export default LegendaryModal;
